'use client';

import { createContext } from 'react';
import { GlobalModalContextType } from './global-modal.types';

export const GlobalModalContext = createContext<GlobalModalContextType>({
  openModal: () => {},
  closeModal: () => {},
  modals: [],
});
