'use client';

import React, { useContext } from 'react';
import { DictionariesContext } from '@contexts/dictionaries/dictionaries.context';
import {
  DictionaryItemCanonicalName,
  fallbackMasterDictionaries,
} from '@utils/dictionaries';
import { FlattenedDictionaries } from '@volvo-cars/content-delivery-client/dictionaries/types';

export function useDictionaries() {
  return useContext(DictionariesContext);
}

/**
 * @param children React child nodes
 * @param dictionaries The dictionaries to use
 * @returns A React Context Provider for dictionaries, enables the use of useDictionaries() hook
 */
export function DictionariesProvider({
  children,
  dictionaries,
  isDictionariesModeActive,
}: React.PropsWithChildren<{
  dictionaries: FlattenedDictionaries;
  isDictionariesModeActive: boolean;
}>) {
  /**
   * @param dictionaryName The name of the dictionary to get, e.g. "Test.test"
   * @returns A translated string from the dictionary, or the dictionary name enclosed in brackets if not found.
   */
  const getDictionaryItemByName = (
    dictionaryName: DictionaryItemCanonicalName,
  ) => {
    if (isDictionariesModeActive) {
      return dictionaryName;
    }

    return (
      dictionaries[dictionaryName] ??
      fallbackMasterDictionaries[dictionaryName] ??
      `[${dictionaryName}]`
    );
  };

  return (
    <DictionariesContext.Provider
      value={{
        dictionaries,
        getDictionaryItemByName,
      }}
    >
      {children}
    </DictionariesContext.Provider>
  );
}
