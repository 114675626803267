import masterDictionaries from './master-dictionaries.json';

export const fallbackMasterDictionaries = { ...masterDictionaries };

export type DictionaryItemCanonicalName =
  keyof typeof fallbackMasterDictionaries;

type DictionaryItemsCanonicalNameRef = Record<
  string,
  DictionaryItemCanonicalName
>;
export function createDictionaryItemsCanonicalNameRef<
  T extends DictionaryItemsCanonicalNameRef,
>(dictItems: T) {
  return dictItems;
}

export function isItemFallbackCanonicalName(
  dictItem: string,
): dictItem is DictionaryItemCanonicalName {
  const fallbackMasterDictionariesKeys = Object.keys(
    fallbackMasterDictionaries,
  );
  return fallbackMasterDictionariesKeys.includes(dictItem);
}
